import discord from '../assets/discord.png'
import twitch from '../assets/twitch.png'
import instagram from '../assets/instagram.png'
import amazon from '../assets/amazon.png'
import tips from '../assets/tips.png'
import of from '../assets/onlyfans.png'
import twitter from '../assets/twitter.png'
import tiktok from '../assets/tiktok.png'

const items = [
    {
        "title": "OnlyFans",
        "subtitle": "Want to see MORE? You've come to the right place <3!",
        "image": of,
        "link": "https://onlyfans.com/ch3azay"
    },
    {
        "title": "Tips / Tipsies <3 ~",
        "subtitle": "If you like my content, let me know with a small donation! It is greatly appreciated <3!",
        "image": tips,
        "link": "https://streamelements.com/ch3azay/tip"
    },
    {
        "title": "My Twitch channel <3",
        "subtitle": "Come and hangout with me and my awesome community! Don't forget to follow me ~~",
        "image": twitch,
        "link": "https://www.twitch.tv/ch3azay"
    },
    {
        "title": "Instagram",
        "subtitle": "Wanna see some pics? Come and take a look at my IG page <3",
        "image": instagram,
        "link": "https://www.instagram.com/ch3azay/"        
    },
    {
        "title": "TikTok",
        "subtitle": "Follow me on TikTok! Let's have some fun!",
        "image": tiktok,
        "link": "https://www.tiktok.com/@ch3azay"        
    }, 
    {
        "title": "Twitter",
        "subtitle": "Follow me on Twitter! Let's discuss!",
        "image": twitter,
        "link": "https://twitter.com/ch3azay"        
    },    
    {
        "title": "My Discord server :)",
        "subtitle": "Continue to hangout with me and my awesome community off-stream! Also, I have a tier system on my Discord, please look at the details in the important notices section: https://bit.ly/3xtyToC .",
        "image": discord,
        "link": "https://discord.gg/GjgsGK9T8w"
    },

    {
        "title": "My Amazon Wishlist <3",
        "subtitle": "If you buy me something from my wish list over $25, you are automatically in my Discord Tier 1 for 2 months, over 50$: Tier 2 for 2 months; over 100$: Tier 3 for 2 months; Over 200$: gets personalized photo plus 3 months of Tier 3!",
        "image": amazon,
        "link": "https://www.amazon.ca/hz/wishlist/ls/1MZMN1WB2MTJI?ref_=wl_share"        
    }
]

export default items
